import React from "react";

export const Control = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      strokeWidth="2px"
      stroke={color}
    >
      <polygon
        className="cls-1"
        points="39.14 44.38 3.86 24 39.14 3.62 39.14 44.38"
      />
      <line className="cls-1" x1="44.14" y1="2.75" x2="44.14" y2="45.25" />
    </svg>
  );
};
