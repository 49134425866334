import Cookies from "js-cookie";

export const storeTokens = (accessToken, tokenType, expiresIn) => {
  // Set the access token cookie with the expiration time in days
  const expiryTimeInDays = expiresIn / 86400; // Convert seconds to days
  Cookies.set("accessToken", `${tokenType} ${accessToken}`, {
    secure: true,
    sameSite: "Strict",
    expires: expiryTimeInDays, // Cookie will expire at the same time as the token
  });
};

export const setToken = (token) => Cookies.set("token", `${token}`);

export const getAccessToken = () => Cookies.get("accessToken");
export const getToken = () => Cookies.get("token");

export const removeAccessTokens = () => Cookies.remove("accessToken");
export const removeToken = () => Cookies.remove("token");
