import React from "react";

import styles from "./ProgressBar.module.css";

export const ProgressBar = ({ value, max, unit, showLabel, small = false }) => {
  const percentage = Math.min(Math.max((value / max) * 100, 0), 100);
  return (
    <div className={styles.progres_containter}>
      <div className={styles.progress}>
        <div
          className={styles.progressBar}
          style={{ width: `${percentage}%` }}
          aria-valuenow={value}
          aria-valuemin={0}
          aria-valuemax={max}
          role="progressbar"
        ></div>
      </div>
      {showLabel && (
        <span className={`${small ? styles.small_text : ""}`}>
          {`${percentage.toFixed(0)}`}
          {unit || "%"} &#124; {max}
          {unit || "%"}
        </span>
      )}
    </div>
  );
};
