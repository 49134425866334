import React from "react";
import { useLocation, useNavigate } from "react-router";

import axios from "axios";

import * as Yup from "yup";

import { storeTokens, setToken } from "../../../../../utils/utils";
import { useAuth } from "../../../../../context/authContext";

import { Form } from "../Form/Form";
import { FormGroup } from "../FormGroup/FormGroup";
import MosysLogo from "../../../../../../shared/img/MoSysLogo.svg";

import styles from "./Login.module.css";

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .email("Invalid email address")
    .required("Username is required"),
  password: Yup.string().required("Password is required"),
});

export const Login = ({ url, logo = <MosysLogo /> }) => {
  const { login } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname || "/";

  const handleLogin = async (data) => {
    const { username, password } = data;
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    try {
      const response = await axios.post(url, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const tokenType = response?.data.token_type
        ? response?.data.token_type.charAt(0).toUpperCase() +
          response?.data.token_type.slice(1).toLowerCase()
        : "Bearer";
      storeTokens(
        response?.data.access_token,
        tokenType,
        response?.data.expires_in,
      );
      setToken(response?.data.access_token);
      login(
        `${tokenType} ${response?.data.access_token}`,
        response?.data.access_token,
      );
      navigate(from, { replace: true });
    } catch (error) {
      throw new Error(
        error?.response?.data?.detail
          ? error?.response?.data?.detail
          : error?.message,
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>{logo}</div>
      <div className={styles.content}>
        <Form
          buttonText="Login"
          formStyle="vertical"
          schema={LoginSchema}
          onSubmit={handleLogin}
        >
          <FormGroup
            label="Email"
            name="username"
            formType="Input"
            type="email"
            formGroupStyle="vertical"
          />
          <FormGroup
            label="Password"
            name="password"
            formType="Input"
            type="password"
            formGroupStyle="vertical"
          />
          <a
            href="https://auth.mo-sys.com/password-reset/"
            className={styles.footer_form}
          >
            Forgot Password?
          </a>
        </Form>
      </div>
      <div className={styles.footer}>
        <p>
          Don&#39;t have an account?{" "}
          <a href="https://auth.mo-sys.com/register_user/">Sign up</a> now
        </p>
      </div>
    </div>
  );
};
