import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Button } from "../Button/Button";

import styles from "./InputNumber.module.css";

export const InputNumber = ({ name, value, ...props }) => {
  const { setIsFieldDirty, onCallback, disabled = false } = props;
  const { control, setValue } = useFormContext();

  const [localValue, setLocalValue] = useState(value || 0);

  useEffect(() => {
    setValue(name, value || 0);
    setLocalValue(value || 0);
  }, [name, value, setValue]);

  const roundToThreeDecimals = (num) => {
    return parseFloat(Number(num).toFixed(3));
  };

  const handleChange = (e) => {
    const inputVal = e.target.value;

    // Allow only numbers, decimal points, and empty values
    if (/^-?\d*\.?\d*$/.test(inputVal)) {
      const newVal = roundToThreeDecimals(inputVal);
      setLocalValue(inputVal);
      setValue(name, isNaN(newVal) ? 0 : newVal);

      if (onCallback) {
        onCallback(isNaN(newVal) ? 0 : newVal);
      }
      setIsFieldDirty(true);
    }
  };

  const handleIncrement = () => {
    if (!disabled) {
      const newVal = roundToThreeDecimals((parseFloat(localValue) || 0) + 1);
      setLocalValue(newVal);
      setValue(name, newVal);

      if (onCallback) {
        onCallback(newVal);
      }
      setIsFieldDirty(true);
    }
  };

  const handleDecrement = () => {
    if (!disabled) {
      const newVal = roundToThreeDecimals((parseFloat(localValue) || 0) - 1);
      setLocalValue(newVal);
      setValue(name, newVal);

      if (onCallback) {
        onCallback(newVal);
      }
      setIsFieldDirty(true);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <div className={styles.numberInput_group}>
          <Button
            onClick={handleDecrement}
            color="light"
            iconType="minusForm"
            iconSize="36"
            iconColor={!disabled ? "#fff" : "#6a7481"}
          />
          <input
            type="text"
            className={styles.numberInput}
            name={field.name}
            id={field.name}
            onBlur={field.onBlur}
            onChange={handleChange}
            value={localValue}
            disabled={disabled}
          />
          <Button
            color="light"
            onClick={handleIncrement}
            iconType="plusForm"
            iconSize="36"
            iconColor={!disabled ? "#fff" : "#6a7481"}
          />
        </div>
      )}
    />
  );
};
