// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.

import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
} from "react";
import { sceneReducer, initialState } from "../reducers/sceneReducer";
import { useAxios } from "../../web-utils/src/services/axiosInstance";

const REACT_APP_API_URL = window.env && window.env.REACT_APP_API_URL;

const SceneContext = createContext();

export const SceneProvider = ({ children }) => {
  const [scenes, dispatch] = useReducer(sceneReducer, initialState);
  const axiosInstance = useAxios();

  const getScenes = useCallback(async () => {
    dispatch({ type: "LOAD_START" });
    try {
      const response = await axiosInstance.get(
        `${REACT_APP_API_URL}scene/all/`,
      );
      dispatch({ type: "LOAD_SCENES", payload: response.data });
    } catch (error) {
      dispatch({
        type: "LOAD_ERROR",
        payload: error?.response?.data?.detail
          ? error?.response?.data?.detail
          : error?.message,
      });
    }
  }, [axiosInstance]);

  const createScene = useCallback(
    async (data) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.post(
          `${REACT_APP_API_URL}scene/`,
          data,
        );
        dispatch({ type: "CREATE_SCENE", payload: response.data });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const updateScene = useCallback(
    async (data) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.patch(
          `${REACT_APP_API_URL}scene/update/`,
          data,
        );
        dispatch({ type: "UPDATE_SCENE", payload: response.data });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const deleteScene = useCallback(
    async (sceneId) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.delete(
          `${REACT_APP_API_URL}scene/delete/${sceneId}/`,
        );
        dispatch({ type: "DELETE_SCENE", payload: response.data });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const filteredScenes = useCallback(
    async (data) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.post(
          `${REACT_APP_API_URL}scene/filtered/`,
          data,
        );
        dispatch({ type: "FILTERED_SCENES", payload: response.data });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const getKeywords = useCallback(async () => {
    dispatch({ type: "LOAD_START" });
    try {
      const response = await axiosInstance.get(
        `${REACT_APP_API_URL}keyword/all`,
      );
      dispatch({ type: "GET_KEYWORDS", payload: response.data });
    } catch (error) {
      dispatch({
        type: "LOAD_ERROR",
        payload: error?.response?.data?.detail
          ? error?.response?.data?.detail
          : error?.message,
      });
    }
  }, [axiosInstance]);

  const addKeywordToScene = useCallback(
    async (data) => {
      try {
        const response = await axiosInstance.post(
          `${REACT_APP_API_URL}scene/add/keyword/`,
          data,
        );
        dispatch({ type: "ADD_KEYWORD", payload: response.data });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail[0]?.msg
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const getUnrealVersions = useCallback(async () => {
    dispatch({ type: "LOAD_START" });
    try {
      const response = await axiosInstance.get(
        `${REACT_APP_API_URL}unreal/all`,
      );
      dispatch({
        type: "GET_UNREAL_VERSIONS",
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: "LOAD_ERROR",
        payload: error?.response?.data?.detail
          ? error?.response?.data?.detail
          : error?.message,
      });
    }
  }, [axiosInstance]);

  const addUnrealVersionToScene = useCallback(
    async (data) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.post(
          `${REACT_APP_API_URL}scene/add/unreal-version/`,
          data,
        );
        dispatch({
          type: "ADD_UNREAL_VERSION",
          payload: response.data,
        });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const buyScene = useCallback(
    async (data) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.post(
          `${REACT_APP_API_URL}scene/buy/`,
          data,
        );
        dispatch({ type: "BUY_SCENE", payload: response.data });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  return (
    <SceneContext.Provider
      value={{
        scenes,
        dispatch,
        getScenes,
        getKeywords,
        createScene,
        updateScene,
        deleteScene,
        filteredScenes,
        addKeywordToScene,
        getUnrealVersions,
        addUnrealVersionToScene,
        buyScene,
      }}
    >
      {children}
    </SceneContext.Provider>
  );
};

export const useScene = () => {
  const context = useContext(SceneContext);
  return context;
};
