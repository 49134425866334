// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useMemo } from "react";

import { ProgressBar } from "../../../../web-utils/src/UI/Component/React/V1/ProgressBar/ProgressBar";
import { Badge } from "../../../../web-utils/src/UI/Component/React/V1/Badge/Badge";
import { withToolTip } from "../../../../web-utils/src/UI/Component/React/V1/Hooks/withToolTip/withToolTip";

import styles from "./DownloadStatus.module.css";

const BadgeComponent = withToolTip(Badge);

// Possible scene current state: "downloading" | "downloaded" | "undownloaded" | "failed download" | "loading" | "loaded" | "failed loading",

const getStatus = (value) => {
  switch (value) {
    case "undownloaded":
      return {
        variant: "warning",
        text: "Not yet downloaded",
      };
    case "downloaded":
      return {
        variant: "success",
        text: "Downloaded and ready to load",
      };
    case "failed download":
      return {
        variant: "error",
        text: "Failed download",
      };
    case "failed loading":
      return {
        variant: "error",
        text: "Failed loading",
      };
    case "loaded":
      return {
        variant: "success",
        text: "Loaded",
      };
    default:
      return "";
  }

};

export const DownloadStatus = ({ scene }) => {
  const badge = useMemo(() => getStatus(scene?.status), [scene?.status]);

  return (
    <div className={styles.container}>
      {scene?.status === "loading" || scene?.status === "downloading" ? (
        <ProgressBar
          value={scene?.download_percent}
          max={100}
          showLabel
          small
        />
      ) : (
        <BadgeComponent
          variant={badge?.variant}
          round={true}
          tooltipText={badge?.text}
          position="left"
        />
      )}
    </div>
  );
};
