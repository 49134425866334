import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "../Button/Button";
import { Icons } from "../Icons/Icons";
import { Alert } from "../Alert/Alert";

import styles from "./Form.module.css";

export const Form = ({
  handleChange,
  onSubmit,
  schema,
  submitButton = true,
  children,
  ...props
}) => {
  const methods = useForm({ resolver: yupResolver(schema) });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
  } = methods;
  const { buttonText = "Set", formStyle = "horizontal" } = props;
  const [error, setError] = useState(null);
  const [isFieldDirty, setIsFieldDirty] = useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [reset, isSubmitSuccessful]);

  const onChange = async (data) => {
    // toogle submit function
  };

  const onSubmitForm = async (data) => {
    try {
      setError(null);
      await onSubmit(data);
    } catch (error) {
      setError({ error: true, message: error?.message });
    }
  };

  return (
    <div>
      {!error ? null : (
        <div className={styles.error_message}>
          <Alert variant="error" message={error?.message} />
        </div>
      )}
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmitForm)}
          className={`${styles[formStyle]}`}
        >
          {React.Children.map(children, (child) => {
            return child.props.name
              ? React.createElement(child.type, {
                  ...{
                    ...child.props,
                    key: child.props.name,
                    onChange,
                    isFieldDirty,
                    setIsFieldDirty,
                  },
                })
              : child;
          })}
          {!submitButton ? null : (
            <div className={styles.submit}>
              <Button color="primary" type="sumbit" disabled={isSubmitting}>
                <span>
                  {isSubmitSuccessful ? (
                    <Icons iconType="isSuccess" iconColor="#ffbf00" sizing />
                  ) : (
                    `${buttonText}`
                  )}
                </span>
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
