// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useState, useEffect } from "react";
import { useWebsocket } from "../../../context/websocketContext/websocketContext";
import { useStudios } from "../../../context/studiosContext";

import { Button } from "../../../../web-utils/src/UI/Component/React/V1/Button/Button";
import { ProgressBar } from "../../../../web-utils/src/UI/Component/React/V1/ProgressBar/ProgressBar";
// import { Form } from "../../../../web-utils/src/UI/Component/React/V1/Form/Form";
// import { FormGroup } from "../../../../web-utils/src/UI/Component/React/V1/FormGroup/FormGroup";

import styles from "./Action.module.css";

// const options = [
//   { value: 0, label: "" },
//   { value: 1, label: "LED wall" },
// ];

export const Action = ({ scene, sceneInStudio, provider }) => {
  const { studios, addSceneToStudio } = useStudios();
  const { studio, error } = studios;
  const { send } = useWebsocket();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (sceneInStudio?.status || error) {
      setIsSubmitting(false);
    }
  }, [sceneInStudio?.status, error]);

  const handleCommand = async (state) => {
    setIsSubmitting(true);
    send({
      type: "event",
      body: {
        event: "SceneInStudioDesiredStateChanged",
        data: {
          studioUUID: studio.mosys_id,
          sceneUUID: scene.mosys_id,
          type: provider?.scene_dimension,
          state,
        },
      },
    });
  };

  // If scene is not in studio.current_scenes, call addSceneToStudio before sending the websocket event otherwise the scene mosys_id return an error undefined
  const handleAddSceneToStudio = async () => {
    const response = await addSceneToStudio({
      scene_id: Number(scene.scene_id),
      studio_id: Number(studio.studio_id),
      status: "undownloaded",
      desired_status: "download",
      mosys_id: scene?.mosys_id,
      download_percent: 0,
    });
    if (response) {
      handleCommand("download");
    }
  };

  // TODO handle loading scene on different target
  // const handleSelect = (data) => {};

  if (sceneInStudio) {
    switch (sceneInStudio?.status) {
      case "downloaded":
        return (
          // <div className={styles.wrapper}>
          //   <Form onSubmit={handleSelect}>
          //     <FormGroup
          //       name="target"
          //       label="Load to"
          //       formType="Select"
          //       options={options}
          //     />
          //   </Form>
            <Button onClick={() => handleCommand("load")}>Load</Button>
          // </div>
        );

      case "downloading":
        return (
          <ProgressBar
            value={sceneInStudio?.download_percent}
            max={100}
            showLabel
            small
          />
        );
      case "loading":
        return (
          <div className={styles.loading_wrapper}>
            <p>{sceneInStudio?.status}</p>
            <ProgressBar
              value={sceneInStudio?.download_percent}
              max={100}
              showLabel
              small
            />
          </div>
        );
      case "loaded":
        return <Button>Operate</Button>;

      default:
        return (
          <Button
            onClick={() => handleCommand("download")}
            disabled={isSubmitting}
          >
            Download
          </Button>
        );
    }
  }
  return (
    <Button onClick={handleAddSceneToStudio} disabled={isSubmitting}>
      Download
    </Button>
  );
};
